import { useState } from "react"

const Checkbox = ({title, change_tags}) => {
    const [checked, setChecked] = useState(false)

    const onClick = (e) => {
        change_tags(!checked, e.target.value)
        setChecked(!checked)
        
    }

    return (
        <div>
            <input onChange={onClick} type="checkbox" className="form-check-input my-1" style={{cursor: "pointer"}} checked={checked} id={title} value={title} />
            <label className="form-check-label ms-2" style={{cursor: "pointer"}} for={title}>{title}</label>
        </div>
    )
}

export default Checkbox