import { useState, useEffect } from 'react';

import {Helmet} from 'react-helmet'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
// import Image from 'react-bootstrap/Image';
// import Workshops from './workshops';

const Quizzes = () => {

    const [quizzes, setQuizzes] = useState([])
    const [sporcles, setSporcles] = useState([])

    const domain = 'https://www.geoworkshops.ca'

    async function getQuizzes() {
        const quizzes_res = await fetch(`${domain}/workshops/quiz/`)
        const quizzes_json = await quizzes_res.json()
        return quizzes_json
    }

    async function getSporcles() {
        const sporcles_res = await fetch(`${domain}/workshops/sporcles/`)
        const sporcles_json = await sporcles_res.json()
        return sporcles_json
    }

    const [contentString, setContentString] = useState("Canadian Geography, Canadian Geographic, Geography Questions, Canadian Geography Questions, RCGS, Royal Canadian Geographical Society, Can Geo Education, Canadian Geographic Education, Geography Education, Geographic Education, Geography Workshops, Geo Workshops, Canadian Geo Workshops, Geography, Learning, Workshops, Quizzes, Geography Quizzes, International Geography Olympiad, Canadian Geography Olympiad, Canadian Geographic Challenge, Geography Challenge, Geo Challenge, High School Geography Resources, Geography Resources, Geography Teacher, Geography Student, Geosciences, Canadian Geosciences, Canadian Geomatics, Geomatics, GIS, High School GIS, High School Geomatics, Fieldwork, Fieldwork Resources, Canadian Fieldwork Resources, Geography Fieldwork Resources, Ben Woodward")

    const [sporcleContentString, setSporcleContentString] = useState("")

    useEffect(() => {
        getQuizzes()
            .then(res => {
                let quizzesString = ""
                for (let x=0; x<res.length; x++) {
                    quizzesString += ", " + res[x].title
                }
                setContentString(contentString + quizzesString)
                setQuizzes(res)
            })
    }, [])

    useEffect(() => {
        getSporcles()
            .then(res => {
                let sporcleString = ""
                for (let x=0; x<res.length; x++) {
                    sporcleString += ", " + res[x].title
                }
                setSporcleContentString(sporcleContentString + sporcleString)
                setSporcles(res)
            })
    }, [])

    return(
        <>
        <Helmet>
            <title>Quizzes - Canadian Geography Workshop Series</title>
            <meta name="description" content="Geography quizzes created for Canadian high school students by former International Geography Olympiad participants." />
            <meta name="keywords" content={contentString + ", " + sporcleContentString}/>
            <meta name="author" content="Ben Woodward" />
            <meta name="author" content="Jake Douglas" />
            <meta name="author" content="Andrew Ding" />
            <meta name="author" content="Malhaar Moharir" />
            <meta name="author" content="Jerry Sun" />
            <meta name="author" content="Jack Cheng" />
        </Helmet>
        {quizzes.length > 0 && sporcles.length > 0 ?
            <section id="about" className="py-3">
                <Container>
                    <h3 className="pt-4">Endless Questions</h3>
                    <Row xs={1} md={2}>
                        <Col>
                            <Card className="m-3 border-light" id="random">
                                <Card.Body>
                                    <Card.Title>Endless Questions</Card.Title>
                                    <Card.Text>Pick a few topics and start answering questions!</Card.Text>
                                    <div className="d-flex justify-content-center text-center">
                                        <a href={'/quiz/random'} className="btn btn-outline-dark mt-2">Get Questions</a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <h3 className="pt-4">In-House Quizzes</h3>
                    <Row xs={1} md={2}>
                            {quizzes.map(quiz => {
                                if (quiz.is_active===true) {
                                    return(
                                        <Col>
                                            <Card className="m-3 border-light" id={quiz.id}>
                                                <Card.Body>
                                                    <Card.Title>{quiz.title}</Card.Title>
                                                    <Card.Text>{quiz.question_count===1 ? quiz.question_count + ' question' : quiz.question_count + ' questions'}</Card.Text>
                                                    <div className="d-flex justify-content-center text-center">
                                                        <a href={'/quiz/' + quiz.id} className="btn btn-outline-dark mt-2">Start Quiz</a>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }
                            })}
                    </Row>
                </Container>
                <Container>
                    <h3 className="pt-4">Our Sporcles</h3>
                    <Row xs={1} md={2}>
                            {sporcles.map(sporcle => {
                                if (sporcle.is_active===true) {
                                    return(
                                        <Col>
                                            <Card className="m-3 border-light" id={sporcle.id}>
                                                <Card.Body>
                                                    <Card.Title>{sporcle.title}</Card.Title>
                                                    <div className="d-flex justify-content-center text-center">
                                                        <a href={sporcle.url} className="btn btn-outline-dark mt-2">Go to Quiz</a>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }
                            })}
                    </Row>
                </Container>
            </section>
            :
                <div style={{"height": "78vh", "background": "white"}} className="d-flex text-center align-items-center justify-content-center">
                    <span className="text-danger">Error: Could not fetch quizzes.</span>
                </div>
            }
        </>
    )
}

export default Quizzes;