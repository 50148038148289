import { useState, useEffect } from 'react'

import {Helmet} from 'react-helmet'

import Card from 'react-bootstrap/Card'
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import mag from '../img/Magdalen Islands.png'
import perce from '../img/Perce Rock - Gaspe.png'
import sable from '../img/Sable Island Horses Walking in Line.png'

import mag_sm from '../img/Magdalen Islands.JPG'
import perce_sm from '../img/Perce Rock - Gaspe.JPG'
import sable_sm from '../img/Sable Island Horses Walking in Line.JPG'

const Home = () => {

    const [authors, setAuthors] = useState([])

    const [upcomingWorkshops, setUpcomingWorkshops] = useState([])
    const [previousWorkshops, setPreviousWorkshops] = useState([])

    const domain = 'https://www.geoworkshops.ca'

    async function getAuthors() {
        const authors_res = await fetch(`${domain}/workshops/active-authors/`)
        const authors_json = await authors_res.json()
        return authors_json
    }

    useEffect(() => {
        getAuthors()
            .then(res => {
                setAuthors(res)
            })
    }, [])

    async function getWorkshops() {
        const series_res = await fetch(`${domain}/workshops/1/`)
        const series_json = await series_res.json()
        return series_json
    }

    useEffect(() => {
        getWorkshops()
            .then(res => {
                console.log(res.upcoming_workshops)
                setUpcomingWorkshops(res.upcoming_workshops)
                setPreviousWorkshops(res.previous_workshops)
            })
    }, [])

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    return(
        <>
            <Helmet>
                <title>Canadian Geography Workshop Series</title>
                <meta name="description" content="Free and high-quality online geography workshops and resources created for Canadian high school students by former International Geography Olympiad participants." />
                <meta name="keywords" content="Canadian Geography, Canadian Geographic, Geography Questions, Canadian Geography Questions, RCGS, Royal Canadian Geographical Society, Can Geo Education, Canadian Geographic Education, Geography Education, Geographic Education, Geography Workshops, Geo Workshops, Canadian Geo Workshops, Geography, Learning, Workshops, Quizzes, Geography Quizzes, International Geography Olympiad, Canadian Geography Olympiad, Canadian Geographic Challenge, Geography Challenge, Geo Challenge, High School Geography Resources, Geography Resources, Geography Teacher, Geography Student, Geosciences, Canadian Geosciences, Canadian Geomatics, Geomatics, GIS, High School GIS, High School Geomatics, Fieldwork, Fieldwork Resources, Canadian Fieldwork Resources, Geography Fieldwork Resources, Ben Woodward"/>
                <meta name="author" content="Ben Woodward" />
                <meta name="author" content="Jake Douglas" />
                <meta name="author" content="Andrew Ding" />
                <meta name="author" content="Malhaar Moharir" />
                <meta name="author" content="Jerry Sun" />
                <meta name="author" content="Jack Cheng" />
                <meta name="author" content="Matthew Woodward" />
            </Helmet>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Workshop Series Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>    
                    Thank you for your interest in the Canadian Geography Workshop Series! 
                    <br/><br/>
                    Some of our workshops are now available on YouTube! 
                    Recorded versions of our workshops can be accessed on the <a href="https://www.youtube.com/@CanadianGeographyWorkshops/playlists">Canadian Geography Workshop Series YouTube Channel</a>. 
                    Slides, resources, quizzes, and activities for each of our workshops are still available on this website.
                    <br/><br/>
                    Workshops 8 to 12 will not be recorded as they are skill-building workshops that are more amenable to a live format. 
                    We hope to deliver these workshops in a live format in the near future. 
                    In the meantime, the slides for these workshops, as well as their associated activities, resources, and quizzes, remain available on this website. 
                    <br/><br/>
                    Kind regards,
                    <br/><br/>
                    The Workshop Series team
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <section>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-none d-md-block w-100 h-100 image-fluid"
                            src={mag}
                            alt="First slide"
                        />
                        <img
                            className="d-block d-md-none w-100 h-100 image-fluid"
                            src={mag_sm}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 className="d-none d-md-block">The Canadian Geography Workshop Series</h3>
                            <h4 className="d-block d-md-none">Canadian Geography Workshop Series</h4>
                            <p className="d-none d-md-block">Online workshops created by former International Geography Olympiad participants</p>
                            <a href="https://www.geoworkshops.ca/workshops/" className="btn btn-danger mb-4">Check out our Workshops</a>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-none d-md-block w-100 h-100 image-fluid"
                            src={perce}
                            alt="First slide"
                        />
                        <img
                            className="d-block d-md-none w-100 h-100 image-fluid"
                            src={perce_sm}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 className="d-none d-md-block">The Canadian Geography Workshop Series</h3>
                            <h4 className="d-block d-md-none">Canadian Geography Workshop Series</h4>
                            <p className="d-none d-md-block">Learn key geographic concepts that help explain our complicated world</p>
                            <a href="https://www.geoworkshops.ca/workshops/" className="btn btn-danger mb-4">Check out our Workshops</a>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-none d-md-block w-100 h-100 image-fluid"
                            src={sable}
                            alt="First slide"
                        />
                        <img
                            className="d-block d-md-none w-100 h-100 image-fluid"
                            src={sable_sm}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 className="d-none d-md-block">The Canadian Geography Workshop Series</h3>
                            <h4 className="d-block d-md-none">Canadian Geography Workshop Series</h4>
                            <p className="d-none d-md-block">Dive deeper into Canada's geography by creating maps, doing fieldwork, and using GIS</p>
                            <a href="https://www.geoworkshops.ca/workshops/" className="btn btn-danger mb-4">Check out our Workshops</a>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>
            <section id="about" className="pb-5">
                <Container>
                    <Row xs={1} md={2}>
                        {/* About */}
                        <Col>
                        <Card className="m-3 border-light">
                            <Card.Body>
                                <Card.Title>
                                    Making geography more accessible to Canadian high school students
                                </Card.Title>
                                <Card.Text>
                                    We have created a collection of high-quality geography, geoscience, and geomatics resources and 
                                    are making them freely available to Canadian high school students. 
                                    We work in collaboration with Canadian Geographic Education, academics, educators, and organizations 
                                    to create and present top-quality geography workshops, activities, and quizzes! 
                                    These resources are intended to help high school students prepare for geography competitions, 
                                    post-secondary studies in geo-disciplines, or wherever else their geographical journey may take them. 
                                    Some things you can learn from our workshops include:
                                    <ul className="py-1">
                                        <li className="py-1">How to read a landscape to understand how it was created</li>
                                        <li className="py-1">The science behind climate and climate change, and climate change's profound effects on humanity</li>
                                        <li className="py-1">Why cities are where they are</li>
                                        <li className="py-1">How to determine the water quality of a stream by looking at water bugs</li>
                                        <li className="py-1">How to identify and learn from rocks, trees, and soils</li>
                                        <li className="py-1">How to do fieldwork in a neighbourhood or around the world</li>
                                        <li className="py-1">How to create effective and visually-appealing digital maps using Geographic Information Systems (GIS)</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                        {/* About Us */}
                        <Col>
                        <Card className="m-3 border-light">
                            <Card.Body>
                                <Card.Title>
                                    Created by former Geo-Olympiad participants
                                </Card.Title>
                                <Card.Text>
                                    We are all former members of teams Canada has sent to the International Geography Olympiad. All of us have a strong knowledge of and passion for all things geo, and we are eager to share that with everyone.
                                </Card.Text>
                                {authors.length > 0 ?
                                    <Row xs={2}>
                                        {authors.map(author => {
                                            return(
                                                <Col className="d-flex justify-content-center text-center">
                                                    <div>
                                                        <a href={'/instructors#author-' + author.id}>
                                                            <Image
                                                                src={author.profile}
                                                                roundedCircle
                                                                fluid
                                                                className="w-25"
                                                            />
                                                        </a>
                                                        <p>{author.first_name + ' ' + author.last_name}</p>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                :
                                    <span className="d-flex justify-content-center text-center text-danger">Error: Could not fetch authors.</span>
                                }
                                <Container className="d-flex justify-content-center">
                                    <a href="/instructors" className="btn btn-outline-dark mt-3">Learn More About Us</a>
                                </Container>
                            </Card.Body>
                        </Card>
                        </Col>
                        {/* <Col> */}
                        {/* Upcoming Workshops */}
                        {/* <Card className="m-3 border-light" id="schedule">
                            <Card.Body>
                                <Card.Title>
                                    Upcoming Workshops
                                </Card.Title>
                                <Card.Text>
                                    Our workshops are not recorded for privacy reasons. Slideshows and other workshop materials are published on our website after each workshop. We encourage participants to attend our workshops in person to get the most out of each one.
                                </Card.Text>
                                <Accordion>
                                    {upcomingWorkshops.length > 0 ?
                                        upcomingWorkshops.map(workshop => {
                                            let dateArray = []
                                            let date = ""
                                            let dateString = null

                                            if (workshop.release_date) {
                                                dateArray = workshop.release_date.split("T")
                                                date = dateArray[0]
                                                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                                                dateString = months[date.split("-")[1]-1] + " " + String(date.split("-")[2]).replace(/^0+/, '') + ", " + String(date.split("-")[0])
                                            }

                                            return(
                                                <Accordion.Item eventKey={workshop.id}>
                                                    <Accordion.Header>
                                                        {dateString ? dateString + " - " + workshop.title : "Date TBD - " + workshop.title }
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                    <Image className="mb-3" src={domain + workshop.thumbnail} alt={workshop.title} fluid/>
                                                    <p><b>Presenters:</b></p> 
                                                    {workshop.authors.map(author => {
                                                        if (author.active===true) {
                                                            return(
                                                                <div className="my-2">
                                                                    <a href={'/instructors#author-' + author.id}>
                                                                        <Image className="profile mx-3" src={domain + author.profile} roundedCircle fluid/>
                                                                    </a>
                                                                    <span className="align-middle">{author.first_name + " " + author.last_name}</span>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}
                                                    <p className="mt-3">{workshop.description}</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    : <span className="d-flex justify-content-center text-center">There are no further workshops in this rendition of the series. Feel free to view the previous workshops on this website.</span>}
                                </Accordion>
                            </Card.Body>
                        </Card>
                        </Col> */}
                        {/* Past Workshops */}
                        <Col>
                        <Card className="m-3 border-light">
                            <Card.Body>
                                <Card.Title>
                                    Our Workshops
                                </Card.Title>
                                <Card.Text>
                                    Click to access video recordings, slideshows, activities, and bonus materials from our workshops.
                                </Card.Text>
                                <Accordion>
                                    {previousWorkshops.length > 0 ?
                                        previousWorkshops.map(workshop => {
                                            const dateArray = workshop.release_date.split("T")
                                            const date = dateArray[0]
                                            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                                            const dateString = months[date.split("-")[1]-1] + " " + String(date.split("-")[2]).replace(/^0+/, '') + ", " + String(date.split("-")[0])
                                            return(
                                                <Accordion.Item eventKey={workshop.id}>
                                                    <Accordion.Header>
                                                        {workshop.title}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <a href={'/workshops/' + workshop.order}>
                                                            <Image className="mb-3" src={domain + workshop.thumbnail} alt={workshop.title} fluid/>
                                                        </a>
                                                        <p><b>Presenters:</b></p> 
                                                        {workshop.authors.map(author => {
                                                            if (author.active===true) {
                                                                return(
                                                                    <div className="my-2">
                                                                        <a href={'/instructors#author-' + author.id}>
                                                                            <Image className="profile mx-3" src={domain + author.profile} roundedCircle fluid/>
                                                                        </a>
                                                                        <span className="align-middle">{author.first_name + " " + author.last_name}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })}
                                                        <p className="mt-3">{workshop.description}</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    : <span className="d-flex justify-content-center text-center text-danger">Error: Could not fetch the workshops.</span>}
                                </Accordion>
                            </Card.Body>
                        </Card>
                        </Col>
                        {/* <Col>
                            <Card className="m-3 border-light" id="faq">
                                <Card.Body>
                                    <Card.Title>
                                        Frequently Asked Questions
                                    </Card.Title>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                Who can attend our workshops?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Our workshops are geared towards Canadian late-elementary to high school students, especially those who are interested in participating in Levels 2 or 3 of the <a href="http://challenge.canadiangeographic.ca/en">Canadian Geographic Challenge</a>. Canadian geography educators are also welcome to sit in on our workshops.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                Do I need to attend all the workshops?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                No. Participants may attend as many or as few workshops as they would like or are able.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                What can participants expect at our workshops?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Our workshops consist of a combination of lecture, group activities and quizzes. Typically, we will present a key concept, and then test participants' knowledge of it using a quiz, activity or discussion. This gives participants the opportunity to think about and digest it during the session, and to ask questions if they are unsure about something.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                What are the activities like?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Our workshops have both individual and group activities. Examples of activities include drawing maps, identifying rocks and trees in a group, creating graphs in Excel, and more! We take up each activity, and students are encouraged but not required so share their answers or what they created.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                                When do the workshops take place?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Workshops always take place on a Saturday or Sunday at 1 PM Eastern Time/10 AM Pacific Time. Exact dates are announced a month or so in advance, since we need to plan workshop dates around assignments, exams, and other university happenings. Please consult the "Upcoming Workshops" section for more detail on scheduling. 
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>
                                                What do we expect of participants?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    <li>Participants are welcome to attend as many or as few workshops as they would like. We encourage participants to attend our interactive Zoom sessions because most of the learning often happens during activities or discussions. That said, workshop materials will be posted after each session.</li>
                                                    <li>Participants may attend the workshops with or without video on (whichever they prefer), but we do require participants to unmute their microphone during group activities/discussions.</li>
                                                    <li>We believe that education should be socially and economically inclusive. All students of all faiths and backgrounds are welcome at our workshops. We have no tolerance for racism, sexism, homophobia, or any other form of discrimination.</li>
                                                    <li>We encourage attendees to make friends! Our workshops are a great opportunity to meet like-minded geography students from across Canada :)</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                                Do I need to be participating in levels 2 or 3 of the Canadian Geographic Challenge to participate in the workshops?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                No. While the workshops do certainly help students prepare for levels 2 or 3 of the Challenge, they are also a great way to learn geography for the sake of gaining a better understanding of the world around them.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col>
                            <Card id="get-involved" className="m-3 border-light">
                                <Card.Body>
                                    <Card.Title>Get Involved!</Card.Title>
                                    <Card.Text>
                                        Are you a Can Geo Challenge or iGeo Alumnus? A teacher or professor in geography, geosciences, geomatics, or related fields? Someone whose career relates to a geo-discipline? If so, don't hesitate to reach out! There are lots of ways to get involved in the workshop series, including:
                                        <ul>
                                            <li>Creating maps, graphics, and other visual aids to help students learn geography and geoscinces concepts visually</li>
                                            <li>Being a guest speaker at one of our workshops</li>
                                            <li>Reviewing workshop material for accuracy and relevance</li>
                                            <li>Designing tutorials and activities to introduce high school students to fieldwork, geomatics, and programming skills</li>
                                            <li>Writing questions for our quiz feature</li>
                                            <li>And more!</li>
                                        </ul>
                                        If you would like to get involved, email us at <a target="_blank" rel="noreferrer" href="mailto:cgwseries@gmail.com">cgwseries@gmail.com</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Home;