import { useState, useEffect } from 'react';

import {Helmet} from 'react-helmet'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image';

const Instructors = () => {

    const [authors, setAuthors] = useState([])

    const domain = 'https://www.geoworkshops.ca'

    async function getAuthors() {
        const authors_res = await fetch(`${domain}/workshops/authors/`)
        const authors_json = await authors_res.json()
        return authors_json
    }

    const [contentString, setContentString] = useState("Canadian Geography, Canadian Geographic, Geography Questions, Canadian Geography Questions, RCGS, Royal Canadian Geographical Society, Can Geo Education, Canadian Geographic Education, Geography Education, Geographic Education, Geography Workshops, Geo Workshops, Canadian Geo Workshops, Geography, Learning, Workshops, Quizzes, Geography Quizzes, International Geography Olympiad, Canadian Geography Olympiad, Canadian Geographic Challenge, Geography Challenge, Geo Challenge, High School Geography Resources, Geography Resources, Geography Teacher, Geography Student, Geosciences, Canadian Geosciences, Canadian Geomatics, Geomatics, GIS, High School GIS, High School Geomatics, Fieldwork, Fieldwork Resources, Canadian Fieldwork Resources, Geography Fieldwork Resources")

    useEffect(() => {
        getAuthors()
            .then(res => {
                let authorsString = ""
                for (let x=0; x<res.length; x++) {
                    authorsString += ", " + res[x].first_name + ' ' + res[x].last_name
                }
                setContentString(contentString + authorsString)
                setAuthors(res)
            })
    }, [])

    return(
        <>
            <Helmet>
                <title>Authors - Canadian Geography Workshop Series</title>
                <meta name="description" content="Geography quizzes created for Canadian high school students by former International Geography Olympiad participants." />
                <meta name="keywords" content={contentString}/>
                <meta name="author" content="Ben Woodward" />
                <meta name="author" content="Jake Douglas" />
                <meta name="author" content="Andrew Ding" />
                <meta name="author" content="Malhaar Moharir" />
                <meta name="author" content="Jerry Sun" />
                <meta name="author" content="Jack Cheng" />
            </Helmet>
            {authors.length > 0 ?
                <section id="about" className="py-3">
                    <Container>    
                        <h3 className="pt-4">Current Instructors</h3>
                        <Row xs={1} md={2}>
                            {authors.map(author => {
                                if (author.active===true) {
                                    return(
                                        <Col>
                                            <Card className="m-3 border-light" id={'author-' + author.id}>
                                                <Container className="text-center">
                                                    <Image
                                                        src={author.profile}
                                                        roundedCircle
                                                        fluid
                                                        className="w-25 mt-4 mb-2"
                                                    />
                                                    <h3 className="mt-2 mb-3">{author.first_name + ' ' + author.last_name}</h3>
                                                </Container>
                                                <Container>
                                                    <p>{author.bio}</p>
                                                </Container>
                                            </Card>
                                        </Col>
                                    )
                                }
                            })}
                        </Row>
                    </Container>
                    <Container>
                        <h3 className="pt-4">Previous Instructors</h3>
                        <Row xs={1} md={2}>
                            {authors.map(author => {
                                if (author.active===false) {
                                    return(
                                        <Col>
                                            <Card className="m-3 border-light" id={'author-' + author.id}>
                                                <Container className="text-center">
                                                    <Image
                                                        src={author.profile}
                                                        roundedCircle
                                                        fluid
                                                        className="w-25 mt-4 mb-2"
                                                    />
                                                    <h3 className="mt-2 mb-3">{author.first_name + ' ' + author.last_name}</h3>
                                                </Container>
                                                <Container>
                                                    <p>{author.bio}</p>
                                                </Container>
                                            </Card>
                                        </Col>
                                    )
                                }
                            })}
                        </Row>
                    </Container>
                </section>
            : 
                <div style={{"height": "78vh", "background": "white"}} className="d-flex text-center align-items-center justify-content-center">
                    <span className="text-danger">Error: Could not fetch instructors.</span>
                </div>
            }
        </>
        
    )
}

export default Instructors;