import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'

import PinchZoomPan from "react-image-zoom-pan";

const Quiz = () => {
    const { id } = useParams();

    const domain = 'https://www.geoworkshops.ca'

    const [quiz, setQuiz] = useState();

    async function getQuiz(id) {
        const quiz_res = await fetch(`${domain}/workshops/quiz/${id}/`)
        const quiz_json = await quiz_res.json()
        return quiz_json
    }

    useEffect(() => {
        getQuiz(id)
            .then(res => {
                setQuiz(res)
                console.log(res)
            })
    }, [id])

    const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
    const [answered, setAnswered] = useState(false);

	const handleAnswerOptionClick = (isCorrect) => {
		if (!answered && isCorrect) {
			setScore(score + 1);
        }
        setAnswered(true)

		// const nextQuestion = currentQuestion + 1;
		// if (nextQuestion < quiz.questions.length) {
		// 	setCurrentQuestion(nextQuestion);
		// } else {
		// 	setShowScore(true);
		// }
	};

    const advance = () => {
        setAnswered(false)

        const nextQuestion = currentQuestion + 1;
		if (nextQuestion < quiz.questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
    }

    //Control Modal
    const [show, setShow] = useState(false)
    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    
    return(
        <>
        {quiz ?
            <section id="about" className="py-3">
                {quiz.is_active === true ?
                    <>
                        {quiz.questions[currentQuestion].image ?
                            <Modal 
                                show={show} 
                                onHide={handleClose}
                                size='xl'
                            >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex justify-content-center align-items-center" style={{"width": "100%", "height": "100%"}}>
                                        <PinchZoomPan maxScale={5} zoomButtons={false} position={'center'}>
                                            <img src={quiz.questions[currentQuestion].image} />
                                        </PinchZoomPan>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        : null}
                        <Container>
                            <h3 className="py-4">{quiz.title}</h3>
                            {showScore ? (
                                <Container className="d-flex flex-column align-items-center justify-content-center text-center my-4" style={{ height: "50vh"}}>
                                    <Card style={{ height: "100%"}} className="d-flex flex-column align-items-center justify-content-center text-center p-4">
                                        <Container>
                                            <h4>Score: {score} out of {quiz.questions.length}</h4>
                                            <div>
                                                <a href={'/quiz/' + quiz.id} className="btn btn-outline-dark mt-3 mx-3">Play Again</a>
                                                <a href="/quizzes" className="btn btn-outline-dark mt-3 mx-3">Try a Different Quiz</a>
                                            </div>
                                        </Container>
                                    </Card>
                                </Container>
                            ): 
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            {currentQuestion + 1}. {quiz.questions[currentQuestion].question}
                                        </Card.Title>
                                        <Card.Text>
                                            <Row xs={1} md={quiz.questions[currentQuestion].image ? 2 : 1}>
                                                {quiz.questions[currentQuestion].image ?
                                                    <Col className="my-3">
                                                        <Image style={{"cursor": "pointer"}} onClick={handleShow} src={quiz.questions[currentQuestion].image} fluid />
                                                        <span className="d-flex text-center justify-content-center">Click on image to enlarge and zoom</span>
                                                    </Col>
                                                : null}
                                                <Col className="my-3">
                                                    <div className="d-flex flex-column">
                                                    {quiz.questions[currentQuestion].answer.map(answer => {
                                                        return(
                                                            <button className={!answered ? "my-2 btn btn-outline-dark" : answer.is_right ? "my-2 btn btn-success" : "my-2 btn btn-danger"} onClick={() => handleAnswerOptionClick(answer.is_right)}>{answer.answer_text}</button>
                                                        )
                                                    })}
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        {answered===true ? 
                                                            <button className="my-2 btn btn-outline-dark" onClick={() => advance()}>{currentQuestion + 1 < quiz.questions.length ? 'Next Question' : 'Show Score'}</button>
                                                        : null}
                                                    </div>  
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            }
                        </Container>
                    </>
                    : <Redirect to="/quizzes" />
                }
            </section>
            : 
            <div style={{"height": "78vh", "background": "white"}} className="d-flex text-center align-items-center justify-content-center">
                <span className="text-danger">Error: Could not fetch questions.</span>
            </div>
        }
        </>
    )
}

export default Quiz;