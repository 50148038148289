import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {Helmet} from 'react-helmet'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Workshops = () => {

    const [upcomingWorkshops, setUpcomingWorkshops] = useState([])
    const [previousWorkshops, setPreviousWorkshops] = useState([])

    const {number}= useParams();

    const domain = 'https://www.geoworkshops.ca'

    async function getWorkshops() {
        const series_res = await fetch(`${domain}/workshops/1/`)
        const series_json = await series_res.json()
        return series_json
    }

    const [contentString, setContentString] = useState("Canadian Geography, Canadian Geographic, Geography Questions, Canadian Geography Questions, RCGS, Royal Canadian Geographical Society, Can Geo Education, Canadian Geographic Education, Geography Education, Geographic Education, Geography Workshops, Geo Workshops, Canadian Geo Workshops, Geography, Learning, Workshops, Quizzes, Geography Quizzes, International Geography Olympiad, Canadian Geography Olympiad, Canadian Geographic Challenge, Geography Challenge, Geo Challenge, High School Geography Resources, Geography Resources, Geography Teacher, Geography Student, Geosciences, Canadian Geosciences, Canadian Geomatics, Geomatics, GIS, High School GIS, High School Geomatics, Fieldwork, Fieldwork Resources, Canadian Fieldwork Resources, Geography Fieldwork Resources, Ben Woodward")

    useEffect(() => {
        getWorkshops()
            .then(res => {
                let workshopTitles = ""
                for (let x=0; x<res.upcoming_workshops.length; x++) {
                    workshopTitles += ", " + res.upcoming_workshops[x].title
                }
                for (let i=0; i<res.previous_workshops.length; i++) {
                    workshopTitles += ", " + res.previous_workshops[i].title
                }
                setContentString(contentString + ", " + workshopTitles)
                setUpcomingWorkshops(res.upcoming_workshops)
                setPreviousWorkshops(res.previous_workshops)
            })
    }, [])

    return(
        <>
        <Helmet>
            <title>Workshops - Canadian Geography Workshop Series</title>
            <meta name="description" content="Geography workshops and resources created for Canadian high school students by former International Geography Olympiad participants." />
            <meta name="keywords" content={contentString}/>
            <meta name="author" content="Ben Woodward" />
            <meta name="author" content="Jake Douglas" />
            <meta name="author" content="Andrew Ding" />
            <meta name="author" content="Malhaar Moharir" />
            <meta name="author" content="Jerry Sun" />
            <meta name="author" content="Jack Cheng" />
            <meta name="author" content="Matthew Woodward" />
        </Helmet>
        {/* {previousWorkshops.length > 0 && upcomingWorkshops.length > 0 ? */}
        <section id="about" className="py-3">
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey={number ? "workshop-" + number : "workshop-1"}>
                    <Row>
                        <Col md={3}>
                            <h4 className="pt-4">Our Workshops</h4>
                            <Nav variant="pills" className="flex-column">
                                {previousWorkshops.length > 0 ?
                                    previousWorkshops.map(workshop => {
                                        return(
                                            <Nav.Item className="nav-click">
                                                <Nav.Link className="nav-link" eventKey={'workshop-' + workshop.order}>{workshop.title}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                : null}
                                {upcomingWorkshops.length > 0 ?
                                    upcomingWorkshops.map(workshop => {
                                        return(
                                            <Nav.Item className="nav-click">
                                                <Nav.Link className="nav-link" eventKey={'workshop-' + workshop.order} disabled>{workshop.title}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                : null}
                            </Nav>
                        </Col>
                        <Col md={9}>
                            <Tab.Content>
                                {previousWorkshops.length > 0 ?
                                    previousWorkshops.map(workshop => {
                                        return(
                                            <Tab.Pane eventKey={'workshop-' + workshop.order}>
                                                <div className="embed-responsive embed-responsive-16by9 py-3 youtube" style={{width: "100%", aspectRatio: "16/10"}}>
                                                    {workshop.playlist ? 
                                                        <iframe title={workshop.title} className="embed-responsive-item" src={workshop.playlist} width="100%" height="100%" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                                                    : workshop.presentation ?
                                                        <iframe title={workshop.title} className="embed-responsive-item" src={workshop.presentation} width="900" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
                                                    : 
                                                        <></>
                                                    }
                                                </div>
                                                <Row xs={1} md={2}>
                                                    <Col>
                                                        <Card className="m-3 border-light">
                                                            <Card.Body>
                                                                <Card.Title>Activities</Card.Title>
                                                                <ListGroup variant="flush">
                                                                    {workshop.activities.map(activity => {
                                                                        return(
                                                                            <a className="list-group-item" target="_blank" rel="noreferrer" href={activity.url}>{activity.title}</a>
                                                                        )
                                                                    })}
                                                                </ListGroup>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className="m-3 border-light">
                                                            <Card.Body>
                                                                <Card.Title>Quizzes</Card.Title>
                                                                <ListGroup variant="flush">
                                                                    {workshop.quizzes.map(quiz => {
                                                                        return(
                                                                            <a className="list-group-item" target="_blank" rel="noreferrer" href={'/quiz/' + quiz.id}>{quiz.title}</a>
                                                                        )
                                                                    })}
                                                                </ListGroup>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className="m-3 border-light">
                                                            <Card.Body>
                                                                <Card.Title>Resources</Card.Title>
                                                                <ListGroup variant="flush">
                                                                    {workshop.resources.map(resource => {
                                                                        return(
                                                                            <div className="list-group-item d-flex justify-content-between align-items-center">
                                                                                <a target="_blank" rel="noreferrer" className="listItem" href={resource.url}>{resource.title}</a>
                                                                                {resource.description ?
                                                                                    <OverlayTrigger
                                                                                        trigger="click"
                                                                                        key={resource.id}
                                                                                        placement={'right'}
                                                                                        overlay={
                                                                                            <Popover id={`popover-positioned-right`}>
                                                                                            <Popover.Header as="h3">Description</Popover.Header>
                                                                                            <Popover.Body>
                                                                                                {resource.description}
                                                                                            </Popover.Body>
                                                                                            </Popover>
                                                                                        }
                                                                                    >
                                                                                        <i style={{cursor: 'pointer'}} className="bi bi-info-circle mx-3 text-dark logo"></i>
                                                                                    </OverlayTrigger>
                                                                                : null}
                                                                            </div>
                                                                            
                                                                        )
                                                                    })}
                                                                </ListGroup>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className="m-3 border-light">
                                                            <Card.Body>
                                                                <Card.Title className="mb-3">Authors</Card.Title>
                                                                {workshop.authors.map(author => {
                                                                    return(
                                                                        <div className="my-2">
                                                                            <a href={'/instructors#author-' + author.id}>
                                                                                <Image className="profile mx-3" src={domain + author.profile} roundedCircle fluid/>
                                                                            </a>
                                                                            <span className="align-middle">{author.first_name + " " + author.last_name}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Card.Body>
                                                        </Card>
                                                        <Card className="m-3 border-light">
                                                            <Card.Body>
                                                                <Card.Title>Feedback?</Card.Title>
                                                                <Card.Text>If you have any suggestions for how we can improve this or other workshops, email us at <a target="_blank" rel="noreferrer" href="mailto:cgwseries@gmail.com">cgwseries@gmail.com</a></Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        )
                                    })
                                : null}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
        {/* : 
            <div style={{"height": "78vh", "background": "white"}} className="d-flex text-center align-items-center justify-content-center">
                <span className="text-danger">Error: Could not fetch workshops.</span>
            </div>
        } */}
        </>
    )
}

export default Workshops;