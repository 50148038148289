import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import cangeologo from '../img/cangeologo.png';

const Footer = () => {
    const year = new Date().getFullYear()

    return(
        <footer className="p-3 bg-dark text-white text-center position-relative">
            <Container>
                <Row xs={1} md={3}>
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                        <span className="footer-font pb-2">Website and workshop materials &copy; {year} CGWS Team</span>
                        <span className="footer-font">Website created by Ben Woodward</span>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center my-2">
                        <a target="_blank" rel="noreferrer" href="mailto:cgwseries@gmail.com"><i className="bi bi-envelope mx-3 text-light logo"></i></a>
                        <a href="https://www.instagram.com/cangeoworkshopseries/"><i className="bi bi-instagram mx-3 text-light logo"></i></a>
                    </Col>
                    <Col>
                        <div>
                            <p className="footer-font">In collaboration with</p>
                            <a href="https://cangeoeducation.ca/en/"><Image fluid src={cangeologo} className="footerLogo" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;