import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

import Home from './pages/home';
import Instructors from './pages/instructors';
import Workshops from './pages/workshops';
import Quizzes from './pages/quizzes';
import Quiz from './pages/quiz';
import Random from './pages/random';

import NavMenu from "./components/navmenu";
import Footer from "./components/footer";

import ReactGA from 'react-ga';

function App() {

  useEffect(() => {
    //https://www.youtube.com/watch?v=H-D-kaCjKfc
    ReactGA.initialize('G-C4YYT7HZ10')
    //Report page view
    ReactGA.pageview(window.location.pathname + window.location.search)
  },[])

  return (
    <BrowserRouter>
      <NavMenu />
      <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/instructors" component={Instructors} />
          <Route exact path="/workshops/:number?" component={Workshops} />
          <Route exact path="/quiz/random" component={Random} />
          <Route exact path="/quizzes" component={Quizzes} />
          <Route exact path="/quiz/:id" component={Quiz} />
          <Redirect to="/" />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};
export default App;
