import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const NavMenu = () => {
    return(
        <Navbar bg="dark" variant="dark" expand="md" className="py-3">
            <Container>
                <Navbar.Brand href="/">
                    CGWS
                    {/* <img
                        src="/logo.svg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    /> */}
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto d-flex align-items-center text-center">
                        <Nav.Link className="px-3" href="/#about">About</Nav.Link>
                        {/* <Nav.Link className="px-3" href="/#faq">FAQ</Nav.Link> */}
                        {/* <Nav.Link className="px-3" href="/#schedule">Upcoming Workshops</Nav.Link> */}
                        <Nav.Link className="px-3" href="/workshops">Our Workshops</Nav.Link>
                        <Nav.Link className="px-3" href="/quizzes">Quizzes</Nav.Link>
                        <Nav.Link className="px-3" href="/#get-involved">Get Involved</Nav.Link>
                        <a href="https://www.youtube.com/@CanadianGeographyWorkshops/playlists" target="_blank" rel="noreferrer" className="mt-4 mt-md-0 ms-md-5 btn-small d-flex align-items-center justify-content-center btn btn-light">YouTube</a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavMenu