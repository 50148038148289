import { useState, useEffect, useCallback } from "react";

import {Helmet} from "react-helmet";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import Checkbox from "../components/checkbox";

import Modal from 'react-bootstrap/Modal'

import PinchZoomPan from "react-image-zoom-pan";

const Random = () => {

    const domain = 'https://www.geoworkshops.ca'

    const [tags, setTags] = useState([])
    const [topics, setTopics] = useState([])
    const [questions, setQuestions] = useState(1)
    const [question, setQuestion] = useState([])

    async function getTags() {
        const tag_res = await fetch(`${domain}/workshops/tags/`)
        const tag_json = await tag_res.json()
        return tag_json
    }

    useEffect(() => {
        getTags()
            .then(res => {
                setTags(res)
            })
    }, [])

    async function getQuestion(topics) {
        const question_res = await fetch(`${domain}/workshops/r/?tags=${topics}`)
        const question_json = await question_res.json()
        return question_json
    }

    useEffect(() => {
        getQuestion(topics)
            .then(res => {
                setQuestion(res)
            })
    }, [topics, questions])

    // const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
    const [answered, setAnswered] = useState(false);

	const handleAnswerOptionClick = (isCorrect) => {
		if (!answered && isCorrect) {
			setScore(score + 1);
        }
        setAnswered(true)
	};

    const advance = () => {
        setAnswered(false)

        setQuestions(questions + 1)
    }

    const end_quiz = () => {
        setShowScore(true)
    }

    const change_tags = useCallback((checked, tagName) => {
        let tagsList = topics
        if (checked) {
            tagsList.push(tagName)
            setTopics(tagsList)
        }
        else if (!checked) {
            for (let x = 0; x < tagsList.length; x++) {
                if (tagsList[x] === tagName) {
                    tagsList.splice(x,1);
                    break;
                }
            }
            setTopics(tagsList)
        }
    })

    //Control Modal
    const [show, setShow] = useState(false)
    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    
    return(
        <>
        <Helmet>
            <title>Practice Questions - Canadian Geography Workshop Series</title>
            <meta name="description" content="Free and high-quality online geography workshops and resources created for Canadian high school students by former International Geography Olympiad participants." />
            <meta name="keywords" content="Canadian Geography, Canadian Geographic, Practice Questions, Practice Geography, Practice Geography Questions, Geography Questions, Random Questions, Infinite Quiz, Geography Game, Geography Quiz Game, Canada Quiz, Canadian Quiz, Canadian Geography Questions, RCGS, Royal Canadian Geographical Society, Can Geo Education, Canadian Geographic Education, Geography Education, Geographic Education, Geography Workshops, Geo Workshops, Canadian Geo Workshops, Geography, Learning, Workshops, Quizzes, Geography Quizzes, International Geography Olympiad, Canadian Geography Olympiad, Canadian Geographic Challenge, Geography Challenge, Geo Challenge, High School Geography Resources, Geography Resources, Geography Teacher, Geography Student, Geosciences, Canadian Geosciences, Canadian Geomatics, Geomatics, GIS, High School GIS, High School Geomatics, Fieldwork, Fieldwork Resources, Canadian Fieldwork Resources, Geography Fieldwork Resources, Ben Woodward"/>
            <meta name="author" content="Ben Woodward" />
            <meta name="author" content="Jake Douglas" />
            <meta name="author" content="Andrew Ding" />
            <meta name="author" content="Malhaar Moharir" />
            <meta name="author" content="Jerry Sun" />
            <meta name="author" content="Jack Cheng" />
        </Helmet>
        {question.length === 1 ?
            <section id="about" className="py-3">
                <>
                    {question[0].image ?
                        <Modal 
                            show={show} 
                            onHide={handleClose}
                            size='xl'
                        >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="d-flex justify-content-center align-items-center" style={{"width": "100%"}}>
                                    <PinchZoomPan maxScale={5} zoomButtons={false} position={'center'}>
                                        <img src={question[0].image} />
                                    </PinchZoomPan>
                                </div>
                            </Modal.Body>
                        </Modal>
                    : null}
                    <Container>
                        <h3 className="py-4">Random Questions by Topic</h3>
                        <Row>
                            <Col md={3}>
                            {tags.length > 0 ?
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Card.Title className="p-2">Choose Topics</Card.Title>
                                        <div className="topics p-2">
                                            {tags.map(tag => {
                                                return(
                                                    <Checkbox title={tag.name} change_tags = {change_tags} />
                                                )
                                            })}
                                        </div>
                                    </Card.Body>
                                </Card>
                                : null
                            }
                            </Col>
                            <Col md={9}>
                            {showScore ? (
                                <Card style={{ height: "100%"}} className="d-flex flex-column align-items-center justify-content-center text-center p-4" style={{ height: "50vh"}}>
                                    <Container>
                                        <h4>Score: {score} out of {questions}</h4>
                                        <div>
                                            <a href={'/quiz/random'} className="btn btn-outline-dark mt-3 mx-3">Play Again</a>
                                            <a href="/quizzes" className="btn btn-outline-dark mt-3 mx-3">Try a Different Quiz</a>
                                        </div>
                                    </Container>
                                </Card>
                            ): 
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            {question[0].question}
                                        </Card.Title>
                                        <Card.Text>
                                            <Row xs={1} md={question[0].image ? 2 : 1}>
                                                {question[0].image ?
                                                    <Col className="my-3">
                                                        <Image style={{"cursor": "pointer"}} src={question[0].image} onClick={handleShow} fluid />
                                                        <span className="d-flex text-center justify-content-center">Click on image to enlarge and zoom</span>
                                                    </Col>
                                                : null}
                                                <Col className="my-3">
                                                    <div className="d-flex flex-column">
                                                    {question[0].answer.map(answer => {
                                                        return(
                                                            <button className={!answered ? "my-2 btn btn-outline-dark" : answer.is_right ? "my-2 btn btn-success" : "my-2 btn btn-danger"} onClick={() => handleAnswerOptionClick(answer.is_right)}>{answer.answer_text}</button>
                                                        )
                                                    })}
                                                    {answered===true ?
                                                        <div className="mt-4 d-flex justify-content-around">
                                                            <button className="my-2 btn btn-outline-dark" onClick={() => end_quiz()}>End Game</button>
                                                            <button className="my-2 btn btn-outline-dark" onClick={() => advance()}>Next Question</button>
                                                        </div>
                                                    : null}
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            }
                            </Col>
                        </Row>
                    </Container>
                </>
            </section>
            : 
            <div style={{"height": "78vh", "background": "white"}} className="d-flex text-center align-items-center justify-content-center">
                <span className="text-danger">Error: Could not fetch questions.</span>
            </div>
        }
        </>
    )
}

export default Random;